footer {
    margin-bottom: 2em;
    margin-top: 1.5em;
    font-size: 0.9em;
    line-height: 1.5em;
    color: #888888;
    text-align: center;
}

footer a, footer a:active, footer a:visited {
    color: #888888;
    transition: 0.4s ease-in;
}

footer a:hover {
    color: #ffffff;
    text-decoration: none;
    transition: 0.4s ease-in;
}

footer div {
    margin: 1em;
}

footer div.logo-box img {
    background-color: #fff;
    padding: 0.2em 0.5em;
    margin-left: 1em;
    margin-right: 1em;
    height: 50px;
}