a, a:visited, a:active {
  color: #c2c2c2;
  transition: 0.2s ease-in;
}

a.external:before {
  font-family: FontAwesome;
  content: "\f2d2";
  padding-right: 0.5em;
  font-size: 0.7em;
  text-decoration: none;
  font-style: normal;
}

a:hover {
  color: #fff;
  cursor: pointer;
}