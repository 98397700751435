
.overlay-modal-hide {
    display: none;
}

.overlay-modal-show {
    display: flex;
}

/* This is on the wrapper for the whole modal */
.overlay-modal {
  width: 92vw;
  height: 92vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 998;
  padding: 4vh 4vw;
}

.modal-outer-container {
  position: relative;
  margin: auto;
}

.modal-body-container {
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  z-index: 999;
}

.modal-close-button {
  font-size: 0.9em;
  padding: 0.8em 0;
  color: #666;
  transition: all ease-in 0.2s;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  align-items: flex-start;
}

.modal-close-button:hover {
  cursor: pointer;
  color: #ffffff;
  transition: all ease-in 0.2s;
  background: rgba(100, 100, 100, 0.25);
  border-radius: 2px;
}

.overlay-modal.overlay-modal-show {
  animation: modal-open 0.3s;
}

@keyframes modal-open {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

.modal-left {
  flex: 1 2 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-left img {
  max-width: 100%;
  max-height: 100%;
}

.modal-right {
  display: flex;
  flex-direction: column;
  flex: 1 3 600px;
  align-items: flex-start;
  justify-content: space-between;
}

.modal-body {
  font-size: 1em;
  line-height: 1.2em;
  margin-top: 1em;
}

.modal-quote {
  margin: 2em;
  align-items: flex-end;
}

h2 {
  text-transform: uppercase;
}

blockquote {
  margin: 3em 0 0 0;
  font-style: italic;
  color: #aaa;
  padding: 0 0.8em;
  border-left: 2px solid #777;
  font-size: 0.9em;
}

blockquote:before {
  position: absolute;
  letter-spacing: 0.15em;
  color: #555;
  margin-top: -2em;
  margin-left: -1.2em;
  font-size: 0.85em;
  text-transform: uppercase;
  font-style: normal;
}

blockquote.modal-references:before {
  content: "Resource References";
}

blockquote.modal-sources:before {
  content: "Image Source";
}

blockquote ul {
  padding: 0;
  margin: 0;
}

blockquote ul > li {
  list-style: none;
  margin: 0.5em 0 0 0;
  padding: 0;
}

@media only screen and (max-width: 599px) {
  .modal-body-container {
    margin: auto 3em;
  }

  .modal-body-container {
    flex-direction: column;
    margin: auto;
  }

  .modal-left {
    flex: 1;
  }

  .modal-left img {
    max-height: 300px;
  }

  .show-mobile {
    display: block;
  }

  .show-desktop {
    display: none;
  }

  .modal-right {
    display: flex;
    flex: 2 1 50%;
    justify-content: left;
  }

  .modal-body {
    margin: 0.6em 1em 1em 1em;
    font-size: 0.95em;
    line-height: 1.3em;
  }

  .modal-quote {
    margin: 1em;
    font-size: 0.85em;
  }

  .modal-close-button {
    background: rgba(0, 0, 0, 0.8);
  }

  .modal-close-button:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  blockquote {
    margin: 2em 0 0 0;
    font-size: 0.9em;
  }

  blockquote:before {
    margin-top: -1.8em;
  }
}

@media only screen and (min-width: 600px) {
  .modal-body-container {
    flex-direction: row;
    margin: auto;
  }

  .modal-body {
    padding: 0 2ch;
    font-size: 1em;
    line-height: 1.2em;
  }

  .show-mobile {
    display: none;
  }

  .show-desktop {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .modal-body-container {
    margin: auto;
    max-width: 980px;
    max-height: 90vh;
  }

  .modal-body {
    padding: 0 3ch;
    font-size: 1.1em;
    line-height: 1.4em;
  }

  h2 {
    font-size: 2em;
  }
}



@media only screen and (min-width: 1400px) {
  .modal-body-container {
    margin: auto;
    max-width: 1000px;
    max-height: 90vh;
  }

  .modal-body {
    padding: 0 3ch;
    font-size: 1.1em;
    line-height: 1.4em;
  }

  h2 {
    font-size: 2em;
  }
}

