#contact-us {
    font-size: 1.2em;
    line-height: 1.5em;
    margin: 1em auto;
    max-width: 760px;
    padding-bottom: 5em;
}

.layout-page h1 {
    line-height: 1.4em;
}

.layout-page img {
    width: 100%;
    max-width: 740px;
    padding: 0 10px;
}

.layout-page p {
    margin-top: 0.5em;
    text-align: justify;
}

.layout-page .button-link {
    border: 1px solid #aaaaaa;
    padding: 1em;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8em;
    height: 100%;
}

@media only screen and (max-width: 999px) {
    .layout-page {
        font-size: 1em;
        line-height: 1.3em;
        padding: 1em;
    }
}