header.camps-header {
    padding: 0;
    display: flex;
    height: 6vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.middle {
    width: 250px;
    text-align: center;
    text-transform: uppercase;
    flex: auto;
}

.logo img {
    max-width: 280px;
    max-height: 6vh;
}

.right {
    width: 1px;
    padding-right: 1em;
}

@media only screen and (max-width: 999px) {
    header.camps-header {
        height: 10vh;
    }
}
