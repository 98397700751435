.layout-container {
    display: flex;
    flex-direction: column;
    height: 94vh;
}

.row {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.info-row {
    flex: 4;
}

.image-cell {
    flex: 1;
    box-sizing: border-box;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    opacity: 0.6;
    transition: 0.4s opacity ease-in-out;
}

.image-cell:hover {
    opacity: 1;
}

.inner-box {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.info-box {
    flex: 8;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 1);
}

.info-box h1 {
    font-weight: 300;
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.info-box h1.revisited {
    font-weight: 700;
    font-size: 2.6em;
}

.info-button-container {
    margin-top: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-button-container a {
    flex: 1 1 min-content;
    margin: 0 1em;
    border: 1px solid #aaaaaa;
    padding: 1em;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8em;
    height: 100%;
}

@media only screen and (max-width: 999px) {
    .layout-mobile {
        display: table;
    }

    .layout-desktop {
        display: none;
    }

    .image-cell {
        display: table-cell;
        height: 25vw;
        width: 25vw;
    }

    .info-row {
        display: table-row;
        flex: 1;
    }

    .info-button-container {
        margin-top: 2em;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-button-container a {
        flex: 1 1 auto;
        margin-bottom: 1em;
        width: 80%;
    }

    .info-box {
        padding: 1.5em 0;
    }

    .info-box h1 {
        font-size: 1.3em;
        line-height: 1.3em;
    }

    .info-box h1.revisited {
        font-size: 1.8em;
    }

    @media only screen and (min-width: 680px) {
        .layout-mobile {
            height: 350vh;
        }
    }
}


@media only screen and (min-width: 1000px) {
    .layout-mobile {
        display: none;
    }

    .layout-desktop {
        display: flex;
    }

    .info-box h1 {
        font-size: 1.8em;
        line-height: 1em;
    }
    
    .info-box h1.revisited {
        font-size: 3em;
    }
}

@media only screen and (min-width: 1200px) {
    .info-box h1 {
        font-weight: 300;
        font-size: 2.6em;
        line-height: 1.2em;
        text-transform: uppercase;
    }
    
    .info-box h1.revisited {
        font-weight: 700;
        font-size: 3.8em;
    }

    .info-button-container a {
        margin: 0 2em;
        font-size: 1em;
    }
}
